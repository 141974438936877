import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/singleInformation/summary/your-order"
import { resetRegistration } from "../redux/registration/registrationAction"

const ConfirmationPaymentPage = () => {
    const dispatch = useDispatch()
    const { ticket, options } = useSelector(({ registrationReducer }) => registrationReducer)

    useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const getVAT = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    const getTotal = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    return (
        <Layout main={false}>
            <SEO title="Confirmation de paiement" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Votre paiement à bien été validé</h2>

                <p>
                    Merci pour votre inscription.</p>
                <div className={"col-lg-8 mx-auto mt-1"}>
                    <YourOrder ticket={ticket} options={options} vat={getVAT()} total={getTotal()} />
                </div>
                <p>
                    Vous recevrez bientôt une confirmation d'inscription par mail contenant votre facture proforma. Pensez à vérifier votre dossier de courrier indésirable.<br />Le code barre pour retirer votre badge à Lille et le lien vers la plateforme virtuelle seront envoyés quelques jours avant l'ouverture du congrès.</p>
                <p>
                    <strong>CONGRÈS FRANÇAIS DE PSYCHIATRIE 2022</strong>
                </p>
            </div>
            <div className={"content-footer footer-alone"}>
                <a href="https://cfp2022.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Connectez-vous</a>
            </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
