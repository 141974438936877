import React from "react"
import PropTypes from "prop-types"
import { FaCheck } from 'react-icons/fa'
import {useSelector} from "react-redux"

import {isDefined} from "../../../utils/data"

const YourOrder = ({ticket, options, total}) => {
    const {user} = useSelector(({apiReducer}) => apiReducer)
    
    var trex = ''
 
    if(isDefined(user)) {	
        if(typeof(user.registered.registered_price) !== 'undefined'){
            var trex = user.registered.registered_price
        }
    }
    
    return (
        <div className={"sr-card summary-block your-order"}>
            <div className={"summary-header"}>
                Votre commande :
            </div>
            <hr/>
            <div className={"summary-body"}>
                {ticket !== null && parseInt(ticket.packages_price) > 0 ?
                    <div className={"row ticket"}>
                        <div className={"col-lg-1"}>
                            <FaCheck/>
                        </div>
                        <div className={"col-lg-8 summary-name"}>
                            {ticket.packages_name}
                        </div>
                        <div className={"col-lg-3 prices"}>
                            {trex !== '' ? trex : ticket.packages_price}€
                        </div>
                    </div>
                    : ""}
                {options.map((option, i) => (
                    parseInt(option.options_price) > 0 && option.included !== 1 ?
                        <div className={"row option"} key={i}>
                            <div className={"col-lg-1"}>
                                <FaCheck/>
                            </div>
                            <div className={"col-lg-8 summary-name"}>
                                {option.options_name}
                            </div>
                            <div className={"col-lg-3 prices"}>
        {option.options_price}€
                            </div>
                        </div>
                        : ""
                ))}
            </div>
            <hr/>
            <div className={"summary-footer"}>
                {isDefined(user) ? isDefined(user.registered.registered_payments) ?
                    parseFloat(user.registered.registered_payments) > 0 ?
                        <div className={"row vat"}>
                            <div className={"col-lg-6 align-left"}>
                                Déjà payé
                            </div>
                            <div className={"col-lg-6 prices"}>
                                ( {parseFloat(user.registered.registered_payments)} € )
                            </div>
                        </div>
                        : "" : "" : ""}
                <div className={"row total"}>
                    <div className={"col-lg-6 align-left"}>
                        Total
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {isDefined(user) ? parseFloat(total) - (parseFloat(user.registered.registered_payments) || 0) + " €"
                        :  parseFloat(total) + " €"}
                    </div>
                </div>
            </div>
        </div>
    )
}

YourOrder.propTypes = {
    ticket:     PropTypes.object.isRequired,
    options:    PropTypes.array.isRequired,
    total:      PropTypes.string.isRequired,
}

export default YourOrder
